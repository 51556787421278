.accueil {
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--color-blue);

}

.svg-bg {
    z-index: 0;
}



.element-accueil {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    color: #FFF;
    font-family: var(--font-secondaire);
    z-index: 2
}

.content {
    margin-top: 70px;
    width: 90vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 22vh;

}

.content-element-head{
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:0px 40px;
}
.h3-titre-description {
    text-shadow: 0px 4px 50px rgba(87, 195, 255, 0.51);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125.612%;
    flex-grow: 0;

}

.mini-description {
    font-family: var(--font-secondaire);
    font-size: 1.5rem;
    font-style: italic;
    line-height: normal;
    flex-grow: 0;

    color: rgba(255, 255, 255, 0.4);
}

.obtenirUnDevis {

    cursor: pointer;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    /* 100% */
    letter-spacing: 0.03125rem;

    border: 1px solid #FFF;
    padding: 8px 35px;
    border-radius: 20px;
    flex-grow: 0;
}


@media screen and (min-width:550px) {
    
    .accueil {
        height: 90vh;

    }
    .content-element-head{
        padding: 0px;
        width:75%;
    }

    
}

@media screen and (min-width:750px) {
  
    .accueil{
        height: 75vh;
    }
    .content {
        width: 100%;
        padding: 0px 70px;
        flex-direction: row;
        justify-content: center;


    }

    .content-element-head {
        width: 75%;
    }


}


@media screen and (min-width:850px) {
   

    .logo {
        width: 11vw;
    }

    .content-element-head{
        width:40%;
    }
}