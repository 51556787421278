.App{
  overflow: hidden;
  box-sizing: border-box

}






.svg-background{
  width:1440 px;
  height:831 px;

  flex-shrink: 0;
}

/*
.content{

  padding: 20px;
}

.text-description{
  height: 70%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

/* text-description 
.h3-titre-description{
  width: 200px;
  color: #FFF;
  text-shadow: 0px 4px 50px rgba(87, 195, 255, 0.51);
  font-family: var(--font-base);
  font-size: 1.5rem;
  font-weight: 700;
}

.mini-description{
  color: #FFF;
font-family: var(--font-secondaire);
font-size: 0.8rem;
font-weight: 300;
}

.obtenirUnDevis{
  height: 35px;
  text-align: center;
color: white;
font-size: 16px;
font-family: var(--font-secondaire);
font-weight: 500;
border-radius: 4.375rem;
border: 2px solid #FFF;

padding: 0 10px 0 10px;

}
*/