.suivie{
    width:100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    color:#fff

}

.suivie-element{
    
    width: 20.75rem;
    height: 23.75rem;
    background-color: var(--color-blue);
    border-radius: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;

}

.titre-suivie{
    font-family: var(--font-secondaire);
    font-size: 1.875rem;
    height: 3rem;

    
}

.mini-description-suivie{
    width:75%;
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem; /* 120% */
    letter-spacing: 0.03125rem;

    margin-bottom: 20px;
}

.form-suivie{
    width:90%;
    height:11rem ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.form-suivie input{
    
    padding: 7px 15px;
    border: 1px solid white;
    border-radius: 20px;
    text-align: center;
}

.form-suivie input::placeholder{
    color:rgba(0,0,0,20%)
}



@media screen and (min-width:550px) {
    
    .suivie-element{
        width:70%;
    }
  
   
    
}

@media screen and (min-width:750px) {
  
    
    .form-suivie{
        flex-direction: row;    
        justify-content: space-around;
        
        width:70%;
        flex-wrap: wrap;
    }
    .form-suivie input{
        width:48%;
    }


   
    .button-suivie{
        flex-basis: 3;
    }


}


@media screen and (min-width:850px) {
   

}