

.images{
    width: 28.49288rem;
    height: 20.79113rem;
    transform: rotate(-12.888deg);
    transform-origin: 0 0;
    position: relative;
    top:35px;
    right: -20px;
    background:no-repeat url("Logistics_and_rocket_2.png") 50% / cover ;
    z-index:0;
}


.logo-images{
    width:40vw;
    position:relative;
    top:80px;
    right:20px;

    z-index:200;
    transform: rotate(-33deg);
}