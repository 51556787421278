.services {
    margin-top: 10px;
    padding: 20px 50px;

    text-align: center;

    font-family: var(--font-secondaire);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-title {
    text-align: center;
    margin-bottom: 30px;
    color: var(--color-orange);

    width: 100%;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.5rem;
    /* 100% */
    letter-spacing: 0.03125rem;
}


.section {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 40px;
}


.sub-title-section {
    color: var(--color-blue);
    width: 100%;

    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 2.5rem */
    text-transform: capitalize;
    margin-bottom: 40px;

}

.img-section{
    width: 13.8125rem;
    height: 14.375rem;
}

.acroche {
    color: var(--color-orange);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;

}

.mini-text {
    color: #505050;

    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
    /* 125% */
    letter-spacing: 0.03125rem;
}

.section5{
    padding:20px;
    border-radius: 0.625rem;
background: #FFF;
box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.20);
}



@media screen and (min-width:550px) {
    
    

    
}

@media screen and (min-width:750px) {
    .services{
        text-align: start;
    }
  
    .section{
      flex-direction: row;
      justify-content: space-around;
      width:70%;
    
    }
    .section-element{
        width:60%;
    }

    .section2, .section4{
        flex-direction: row-reverse;
    }
   

}


@media screen and (min-width:850px) {
   

}