.wrapperDiv {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mySwiper {
    width: 100%;
    height: 70%;
    flex-shrink: 0;
}

.img-slider {
    width: 100%;
    height: 80px;
}


