footer {
    background-color: var(--color-blue);
    width: 100%;
    height: 100vh;
    color: white;
    padding: 50px;
    font-family: "Manrope";


}

.footer-title {

    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footer-mini-text {
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.5);
    font-family: var(--font-secondaire);
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 100;
    line-height: normal;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

}

.privacy {

    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 119.6%;
}

.reseaux {
    margin-top: 20px
}

.reseaux-title {

    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 119.6%;

}

.fleche {
    transform: rotate(-45deg);
}

.links {
    margin-left: 15px;

}

.links a {
    text-decoration: none;
    color: white;

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 119.6%;
}

.email {
    margin-top: 30px;

    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.logo-footer{
    height: 180px;
}


@media screen and (min-width:550px) {
    footer{
        height: 55vh;
    }

    .footer-element{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        column-gap: 2em;
    }
  

    
}

@media screen and (min-width:750px) {
  
    


}


@media screen and (min-width:850px) {
   

    
}