:root{
    --color-orange:#F8AA27;
    --color-black:#1E1E1E;
    --color-blue:#0F9EFA;

    --font-base:'Helvetica Neue';
    --font-secondaire:'Roboto'
}


.btn-style-spec{
    cursor: pointer;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 100% */
    letter-spacing: 0.03125rem;

    border: 1px solid #FFF;
    padding: 8px 35px;
    border-radius: 20px;
    flex-grow: 0;
}

.btn-style-spec:hover{
    background-color: white;
    color: var(--color-blue);
}

/*
    animate du button special
 */
@keyframes animate-btn {

    to{
        border-radius: 0;
    }
}