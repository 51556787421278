.header{
    position: absolute;
    top:20px;

    align-self: center;

    height: 3rem;
    width:100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 0 25px;
    z-index:0;

}

.logo{
    width: 40vw;
}
.menu{
    position: relative;
}

.logo-menu{
    width:50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ligne1{
    margin-bottom: 13px;
}
.ligne{
    display: block;
    width: 35px;
    height: 0.2rem;
    border: 1px solid;
    background-color: #FFF;
}

.showElementNav{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;

    padding: 20px;
    border-radius: 5px;
   

    

}
.localLink{
    text-decoration: none;

    color: var(--color-black);
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 120% */
    letter-spacing: 0.5px;
}
.hideElementNav{
    display: none;
}

.obtenirDevise{
    background-color: var(--color-orange);
    cursor: pointer;
    border:1px solid;
    border-radius: 70px;
    color: #FFF;
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 12px;
    font-style: normal;
    line-height: 24px; /* 120% */
    letter-spacing: 0.5px;

}

.service{
    background-color: var(--color-blue);
    color:white;
    padding:0px 13px;
    border-radius: 10px;
}

@media screen and (min-width:550px) {
    .logo-menu{
        display: none;
    }
    .logo{
        width:20vw;
    }
    .hideElementNav{
        width:100%;
            display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        
    }


    .header{
        padding: 10px 5px;
        background-color: #FFF;
        width:80%;
        height: 7vh;
    }

    /**/
    
    .menu{
        width:70%;
    }

   
}

@media screen and (min-width:750px) {
   

   .header{
    width:70%;
   }
   
   .menu{
    width:60%;
   }
}


@media screen and (min-width:850px){
     .menu{
        width:50%;
     }
    .logo{
        width:12vw;
    }
}